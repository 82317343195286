





















import Vue from 'vue'
import Component from 'vue-class-component'
import MemberInfo from '@/models/MemberInfo'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { CorpMember } from '@/gateway/affiliate/CorpMembers'
import AccountManagement from '@/views/user/AccountManagement.vue'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { AccountManagement }
})
export default class Account extends Vue {
  memberInfo!: MemberInfo
  corpMembers: Array<CorpMember> = []

  async getCorpAllStatus (memberInfo: MemberInfo): Promise<CorpStatus | undefined> {
    const corpAll: CorpAll = await GetCorpAll.getInstance().request({ corp_id: memberInfo.corp_id })
    return corpAll.status
  }

  async created () {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.replace('/signUp')
      return
    }
    const memberInfo = this.memberInfo || MemberInfoGateway.getMemberInfo()
    const corpStatus = await this.getCorpAllStatus(memberInfo)
    const isFormComplete = corpStatus === Number(CorpStatus.FORM_COMPLETE)
    if (isFormComplete) {
      await this.$router.replace('upload')
      return
    }
    const isRegisteredStatus = Number(corpStatus) < Number(CorpStatus.FORM_COMPLETE)
    if (!corpStatus || isRegisteredStatus) {
      this.$message({ message: '문서 작성이 완료되지 않은상태입니다. 문서작성 페이지로 돌아갑니다.', type: 'warning' })
      await this.$router.replace('documents')
    }
  }
}
